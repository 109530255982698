#okta-sign-in {
  .auth-container.main-container {
    background: rgba(242,244,245,1);
    width: 400px;
    box-shadow: none;
    max-width: 100%;
  }

  .button, button, input[type="submit"], .button:visited {
      padding: 1.0rem 1.6rem;
  }

  .o-form-theme {
      padding: 0px;
  }

  .auth-footer {
      margin-top: 10px;
  }

  .auth-content{
      padding: 0px 42px 20px;
  }

  .button-wide, .button-wide.button-primary {
      line-height: 40px;
  }
}
