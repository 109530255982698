body {
  margin: 0px;
  padding: 0px;
}

.background {
  background: url('/images/back.jpg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  height: 100vh;
}
