.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 10px;

  width: 100%;
  height:100%;
  min-height: 100vh;
  margin: 0;
  overflow-y:scroll;

  background: url('/images/back.jpg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.login_errors {
  background-color: #fff;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
  font-family: "montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #5c6971;
}

.login_errors div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
}

.box {
  color: #5c6971;
  position: relative;
  overflow: auto;
  border-radius: 3px;
  height: auto;
  width: 400px;
  min-width: 300px;
}

.box .header {
  padding-top: 50px;
  border-bottom: none;
  padding-bottom: 30px;
  padding: 30px 90px 40px;
  position: relative;
  z-index: 10;
}

.box .header .logo {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 40px;
}

.box .header .welcome {
  margin: 30px 0px;
  color: black;
  text-align: center;
}

.box .content {
  padding: 0px 42px 30px;
}

.box .content .title {
  font-size: 16px;
  text-align: center;
  padding-bottom: 15px;
  font-weight: bold;
}
