.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 10px;

  width: 100%;
  height:100%;
  min-height: 100vh;
  margin: 0;
  overflow-y:scroll;

  background: url('/images/back.jpg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.card {
  width: 200px;
  height: 120px;
}

.button {
  width: 100%;
}

.icon {
  margin: 15px auto 10px auto;
}
