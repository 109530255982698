#okta-sign-in.auth-container {
  .okta-sign-in-header.auth-header {
    padding-top: 50px;
    border-bottom: none;
  }

  .okta-form-title {
    display: none;
  }

  .o-form-button-bar {
    background: transparent;
    border: none;
    padding: 0 0 5px;
    border-radius: 0;
    box-shadow: none;
  }

  .button-primary {
    background: #3BB0C9;
    border: 0;
    line-height: 18px;

    &:hover {
      background: #3EB9D3;
      border-color: #5DA9BA;
      border-bottom-color: #5DA9BA;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .auth-footer {
    text-align: center;
    .js-help {
      display: none;
    }

    .help-links.js-help-links {
      display: block !important;
      text-align: center;

      .js-forgot-password {
        text-align: center;
        color: #3D899A;

        &:hover, &:active {
          color: #3BB0C9;
        }
      }

      .js-help-link {
        display: none;
      }
    }

    .js-back {
      text-align: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline;
      margin: 0;
      padding: 0;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}
